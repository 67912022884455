import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {LanguageMenuComponent} from '@common/core/ui/language-menu/language-menu.component';

@Component({
    selector: 'language-menu-wrapper',
    templateUrl : 'language-menu-wrapper.component.html',
    styleUrls : ['language-menu-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class LanguageMenuWrapperComponent implements OnInit{
    @Input() withTitle = true;
    @ViewChild(LanguageMenuComponent) languageMenu: LanguageMenuComponent;

    constructor() {
        console.log('lang', {lang: this.languageMenu});
    }


    ngOnInit() {
    }

}
