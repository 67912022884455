import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {CurrentUser} from '@common/auth/current-user';
import {AuthService} from '@common/auth/auth.service';
import {Settings} from '@common/core/config/settings.service';
import {BreakpointsService} from '@common/core/ui/breakpoints.service';
import {ThemeService} from '@common/core/theme.service';
import {NavbarDropdownItem} from '@common/core/config/app-config';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {NavSidebarUserMenuComponent} from '../../../../../app/web-player/nav-sidebar/nav-sidebar-user-menu/nav-sidebar-user-menu.component';
import {Subscription} from 'rxjs';
import {LanguageMenuComponent} from '@common/core/ui/language-menu/language-menu.component';
import {LanguageMenuWrapperComponent} from '@common/core/ui/language-menu-wrapper/language-menu-wrapper.component';
import {WebPlayerState} from "../../../../../app/web-player/web-player-state.service";

@Component({
    selector: 'logged-in-user-menu',
    templateUrl: './logged-in-user-menu.component.html',
    styleUrls: ['./logged-in-user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedInUserMenuComponent {
    @Input() hideRegisterButton = false;
    @Input() hideConfigItems = false;
    @Input() forceNotifButton = false;
    @Output() itemClicked = new EventEmitter();
    private subscription: Subscription;

    constructor(
        public currentUser: CurrentUser,
        public auth: AuthService,
        public config: Settings,
        public breakpoints: BreakpointsService,
        public theme: ThemeService,
        public settings: Settings,
        public bottomSheet: MatBottomSheet,

    ) {
    }


    public shouldShowMenuItem(item: NavbarDropdownItem): boolean {
        const hasPermission = !item.permission || this.currentUser.hasPermission(item.permission);
        const hasRole = !item.role || this.currentUser.hasRole(item.role);
        const passes = !item.showFn || item.showFn(this.settings, this.currentUser);
        return hasPermission && hasRole && passes;
    }

    public onItemClick() {
        this.itemClicked.emit();
    }


    public openLangMenu() {
        this.subscription?.unsubscribe();
        this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(r => {
            this.subscription = this.bottomSheet.open(LanguageMenuWrapperComponent).instance.languageMenu?.languageSelected?.subscribe(() => {
                this.bottomSheet.dismiss();
            });
        });
        this.bottomSheet.dismiss();

    }

    get isMobile(){
        return this.breakpoints.isMobile$;
    }
}
