import { Component, Input } from '@angular/core';
import { Channel } from '../../../../admin/channels/channel';
import { WebPlayerUrls } from '../../../web-player-urls.service';
import { CurrentUser } from '@common/auth/current-user';
import { CHANNEL_MODEL_TYPES } from '../../../../models/model_types';
import {Settings} from '@common/core/config/settings.service';
import {ThemeService} from '@common/core/theme.service';

@Component({
    selector: 'channel-content',
    templateUrl: './channel-content.component.html',
    styleUrls: ['./channel-content.component.scss'],
})
export class ChannelContentComponent {
    @Input() channel: Channel;
    @Input() nested = false;
    @Input() queney = false;
    @Input() freeflow = false;
    @Input() isGenrePage?;
    @Input() isSingersPage?;



    public modelTypes = CHANNEL_MODEL_TYPES;

    constructor(
        public urls: WebPlayerUrls,
        public user: CurrentUser,
        public settings: Settings,
        private theme: ThemeService,


    ) {
    }


    get parent() {
        return this.channel;
    }
    public logoUrl() {
        return this.settings.get(`branding.logo_${this.theme.isDarkMode() ? 'light' : 'dark'}`);
    }
}
