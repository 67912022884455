<p style="text-align:center" trans>
  Choisissez votre mode de paiement !
</p>
<div class="methods">
  <ng-container *ngIf="country ;else allMethods;">
    <div class="method-card" (click)="SubmitMethod('eklectic')"
      *ngIf="settings.get('billing.eklectic.enable') && (country=='TUN')">
      <img src="client/assets/images/Orange_logo.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p trans>Solde</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('stripe')"
      *ngIf="settings.get('billing.stripe.enable') && (country!='TUN')">
      <img src="client/assets/images/carte.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p trans>Carte bancaire</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('paymee')"
      *ngIf="settings.get('billing.paymee.enable') && (country=='TUN')">
      <img src="client/assets/images/carte.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p>Carte bancaire</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('voucher')"
      *ngIf="settings.get('billing.voucher.enable') && (country=='TUN')">
      <img src="client/assets/images/runpay.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p trans>RunPay Voucher</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('sob_flous')"
      *ngIf="settings.get('billing.sob_flous.enable') && (country=='TUN')">
      <img src="client/assets/images/sobflous.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p>SobFlous</p>
    </div>
  </ng-container>
  <ng-template #allMethods>
    <div class="method-card" (click)="SubmitMethod('eklectic')">
      <img src="client/assets/images/Orange_logo.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p trans>Solde</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('stripe')">
      <img src="client/assets/images/carte.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p trans>Carte bancaire</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('paymee')"
      *ngIf="settings.get('billing.paymee.enable') && (country=='TUN')">
      <img src="client/assets/images/carte.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p>Carte bancaire</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('voucher')">
      <img src="client/assets/images/runpay.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p trans>RunPay Voucher</p>
    </div>
    <div class="method-card" (click)="SubmitMethod('sob_flous')">
      <img src="client/assets/images/sobflous.png" width="100%" style="height: 100px;object-fit: contain;" />
      <p>SobFlous</p>
    </div>
  </ng-template>
</div>