<div style="display: flex;align-items: center;justify-content: start;">
    <button class="back-btn" style="margin-top:15px">
        <mat-icon svgIcon="keyboard-arrow-left" routerLink="/"></mat-icon>
    </button>
    <h1
        style="text-align: center;font-weight: 700 !important;height: 25.71px;margin-top: 20px;word-break: break-word;width: 80%;">
        {{searchControl.value}}</h1>
</div>
<div class="input-container search-input-container">
    <div class="form-wrapper">
        <div class="form-search">
            <form (ngSubmit)="blurSearchInput()">
                <input type="text" trans-placeholder placeholder="Search..." [formControl]="searchControl" #searchInput class="search-input">
            </form>

            <mat-icon class="search-icon" svgIcon="search" [class.hidden]="searching || searchControl.value"></mat-icon>

            <button type="button" class="no-style" (click)="clearSearch()">
                <mat-icon class="close-icon" svgIcon="close" [class.hidden]="searching || ! searchControl.value">
                </mat-icon>
            </button>
        </div>

    </div>
    <div class="tab-nav">
        <a class="tab-nav-item" [routerLink]="getRouterLink()" [class.active]="activeTabIs('topResults')" trans>All</a>
        <a class="tab-nav-item" [routerLink]="getRouterLink('artists')" [class.active]="activeTabIs('artists')"
            trans>Artists</a>
        <a class="tab-nav-item" [routerLink]="getRouterLink('songs')" [class.active]="activeTabIs('songs')"
            trans>Songs</a>
        <a class="tab-nav-item" [routerLink]="getRouterLink('albums')" [class.active]="activeTabIs('albums')"
            trans>Albums</a>
        <a class="tab-nav-item" [routerLink]="getRouterLink('playlists')" [class.active]="activeTabIs('playlists')"
            trans>Playlists</a>
        <!-- <a class="tab-nav-item" [routerLink]="getRouterLink('users')" [class.active]="activeTabIs('users')"
            trans>People</a> -->
    </div>
    <loading-indicator [isVisible]="searching" class="small"></loading-indicator>
</div>

<ad-host slot="ads.general_top" class="margin-top"></ad-host>

<!-- <div class="main-header" *ngIf="hasResults()">
    <div class="title" *ngIf="!state.isMobile">
        <span trans>Search results for</span>
        <span>« {{searchTerm}} »</span>
    </div>
    <div class="tab-nav" *ngIf="!state.isMobile">
        <a class="tab-nav-item" [routerLink]="getRouterLink()" [class.active]="activeTabIs('topResults')" trans>Top
            Results</a>
        <a class="tab-nav-item" *ngIf="results.artists?.length" [routerLink]="getRouterLink('artists')"
            [class.active]="activeTabIs('artists')" trans>Artists</a>
        <a class="tab-nav-item" *ngIf="results.tracks?.length" [routerLink]="getRouterLink('songs')"
            [class.active]="activeTabIs('songs')" trans>Songs</a>
        <a class="tab-nav-item" *ngIf="results.albums?.length" [routerLink]="getRouterLink('albums')"
            [class.active]="activeTabIs('albums')" trans>Albums</a>
        <a class="tab-nav-item" *ngIf="results.playlists?.length" [routerLink]="getRouterLink('playlists')"
            [class.active]="activeTabIs('playlists')" trans>Playlists</a>
        <a class="tab-nav-item" *ngIf="results.users?.length" [routerLink]="getRouterLink('users')"
            [class.active]="activeTabIs('users')" trans>People</a>
    </div>
</div> -->

<section class="tabs">
    <div class="tab top-results-tab" *ngIf="hasResults() && activeTabIs('topResults')">
        <div class="top-results-row" *ngIf="results.artists?.length">
            <div class="header">
                <div class="title" trans>Artists</div>
                <a class="link hover-underline" [routerLink]="getRouterLink('artists')"
                    *ngIf="results.artists.length > 5">({{'See all' | trans}} {{results.artists.length}}
                    )</a>
            </div>
            <media-grid class="artists-row">
                <artist-item *ngFor="let artist of results.artists | slice:0:5" [artist]="artist"
                    [contextMenu]="{item: artist, type: 'artist'}"></artist-item>
            </media-grid>
        </div>

        <div class="top-results-row" *ngIf="results.albums?.length">
            <div class="header">
                <div class="title" trans>Albums</div>
                <a class="link hover-underline" [routerLink]="getRouterLink('albums')"
                    *ngIf="results.albums.length > 5">({{'See all' | trans}} {{results.albums.length}}
                    )</a>
            </div>
            <media-grid class="albums-row">
                <album-item *ngFor="let album of results.albums | slice:0:5" [album]="album"
                    [contextMenu]="{item: album, type: 'album'}"></album-item>
            </media-grid>
        </div>

        <div class="top-results-row" *ngIf="results.playlists?.length">
            <div class="header">
                <div class="title" trans>Playlists</div>
                <a class="link hover-underline" [routerLink]="getRouterLink('playlists')"
                    *ngIf="results.playlists.length > 5">({{'See all' | trans}} {{results.playlists.length}}
                    )</a>
            </div>
            <media-grid class="playlists-row">
                <playlist-item *ngFor="let playlist of results.playlists | slice:0:5" [playlist]="playlist"
                    [contextMenu]="{item: playlist, type: 'playlist'}"></playlist-item>
            </media-grid>
        </div>
        <div class="top-results-row" *ngIf="results.tracks?.length">
            <div class="header">
                <div class="title" trans>Songs</div>
            </div>
        </div>
        <search-page-track-list [tracks]="results.tracks"></search-page-track-list>

        <div class="top-results-row" *ngIf="results.users?.length">
            <div class="header">
                <div class="title" trans>People</div>
                <a class="link hover-underline" [routerLink]="getRouterLink('users')"
                    *ngIf="results.users.length > 5">({{'See all' | trans}} {{results.users.length}}
                    )</a>
            </div>
            <media-grid class="users-row">
                <user-item *ngFor="let user of results.users | slice:0:5" [user]="user"></user-item>
            </media-grid>
        </div>
    </div>
    <div class="qsp-album-channel" *ngIf="activeTabIs('albums')">
        <media-grid class="tab albums-tab">
            <album-item *ngFor="let album of results.albums" [album]="album"
                [contextMenu]="{item: album, type: 'album'}"></album-item>
        </media-grid>
    </div>

    <div class="qsp-artist-channel" *ngIf="activeTabIs('artists')">
        <media-grid class="tab artists-tab">
            <artist-item *ngFor="let artist of results.artists" [artist]="artist"
                [contextMenu]="{item: artist, type: 'artist'}"></artist-item>
        </media-grid>

    </div>


    <div class="tab songs-tab" *ngIf="activeTabIs('songs')">
        <search-page-track-list [tracks]="results.tracks"></search-page-track-list>
    </div>



    <media-grid class="tab playlists-tab" *ngIf="activeTabIs('playlists')">
        <playlist-item *ngFor="let playlist of results.playlists" [playlist]="playlist"
            [contextMenu]="{item: playlist, type: 'playlist'}"></playlist-item>
    </media-grid>

    <media-grid class="tab users-tab" *ngIf="activeTabIs('users')">
        <user-item *ngFor="let user of results.users" [user]="user" [contextMenu]="{item: user, type: 'user'}">
        </user-item>
    </media-grid>
</section>

<no-results-message svgIcon="search" *ngIf="!hasResults() && hasSearchTerm() && searchedOnce">
    <span primary-text><span trans>No results for</span> « {{searchControl.value}}
        »</span>
    <span secondary-text trans>Please check your spelling or try using different keywords.</span>

</no-results-message>

<no-results-message svgIcon="search" *ngIf="state.isMobile && (!hasSearchTerm() || !searchedOnce)">
    <span primary-text><span trans>Search</span> {{settings.get('branding.site_name')}}</span>
    <span secondary-text trans>Find artists, albums, songs, playlists and more.</span>
</no-results-message>

<ng-container *ngIf="!hasResults()">
    <div class="stats be-container" *ngIf="channels$ | async as channels">
        <channel-show [queney]="true" [channel]="channel" class="center-channel-title" *ngFor="let channel of channels">
        </channel-show>
    </div>
</ng-container>
<ad-host slot="ads.general_bottom" class="margin-top margin-bottom"></ad-host>