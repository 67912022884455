<auth-page>
    <form (ngSubmit)="sendPasswordResetLink()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="page-header" trans>Enter your phone number below and we will send you an SMS with your new password.</div>

        <div class="page-content">
            <div class="input-container">
                <label for="phone" trans>Phone Number</label>
                <input type="text" formControlName="phone" id="phone" required>
                <p class="error big-error" *ngIf="errors.phone">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.phone}}</span>
                </p>
            </div>

            <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="loading$ | async" trans>Continue</button>
            <button mat-raised-button class="primary-button" color="default" type="submit" [routerLink]="['/login']" trans>Back To Login</button>
        </div>
    </form>
    <p style="text-align: center; margin-top:20px">
        <a [routerLink]="['/forgot-password']" trans>Reset your password with Email.</a>
    </p>
</auth-page>
<loading-indicator [isVisible]="state.loading" class="overlay"></loading-indicator>

