import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {finalize, take} from 'rxjs/operators';
import {BackendErrorResponse} from '@common/core/types/backend-error-response';
import {Users} from '@common/auth/users.service';
import {CurrentUser} from '@common/auth/current-user';
import {Toast} from '@common/core/ui/toast.service';
import {Translations} from '@common/core/translations/translations.service';
import {Localizations} from '@common/core/translations/localizations.service';
import {QueneyBootstrapper} from '../../../../app/bootstrapper.service';
import {HttpCacheClient} from '@common/core/http/http-cache-client';
import {Settings} from '@common/core/config/settings.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'language-menu',
    templateUrl: 'language-menu.component.html',
    styleUrls: ['language-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LanguageMenuComponent {
    @Output() public languageSelected = new EventEmitter();
    @Input() withTitle = true;


     languages = new BehaviorSubject(null);

    constructor(
        private users: Users,
        private toast: Toast,
        private currentUser: CurrentUser,
        private i18n: Translations,
        public localizations: Localizations,
        private cache: HttpCacheClient,
        private settings: Settings
    ) {
        this.localizations.all().subscribe(res => {
            console.log('langs', {res});
            const locals = res?.localizations ?? [];
            this.languages.next(locals.map(item => item.model));
        });
    }




    changeLanguage(language) {
        this.languageSelected.emit(language);
        this.users.update(this.currentUser.get('id'), {
            language
        })
            .subscribe((rsp) => {
                this.toast.open('Language Updated');
                this.localizations.get(language)
                    .subscribe(async (response) => {
                        await this.cache.forceExpireRequest('bootstrap-data');
                        this.i18n.setLocalization(response.localization);
                    });
            });
    }
}
