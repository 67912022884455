<media-grid [carousel]="isCarousel" [freeflow]="freeflow">
    <ng-container *ngFor="let item of (pagination$ | async)?.data;let i = index">
        <ng-container [ngSwitch]="item.model_type">
            <track-item (playTrack)="playChannel(i)" [isTrackChannel]="isTrackChannel" [disablePlayback]="channel.config.disablePlayback" *ngSwitchCase="modelTypes.track" [track]="item" [contextMenu]="{item: item, type: 'track'}"></track-item>
            <album-item [disablePlayback]="channel.config.disablePlayback" *ngSwitchCase="modelTypes.album" [album]="item" [contextMenu]="{item: item, type: 'album'}"></album-item>
            <artist-item [disablePlayback]="channel.config.disablePlayback" *ngSwitchCase="modelTypes.artist" [artist]="item" [contextMenu]="{item: item, type: 'artist'}"></artist-item>
            <playlist-item *ngSwitchCase="modelTypes.playlist" [playlist]="item" [contextMenu]="{item: item, type: 'playlist'}"></playlist-item>
            <genre-item *ngSwitchCase="modelTypes.genre" [genre]="item"></genre-item>
            <user-item *ngSwitchCase="modelTypes.user" [user]="item"></user-item>
        </ng-container>
    </ng-container>
</media-grid>
<loading-indicator [isVisible]="loading$ | async" class="inline"></loading-indicator>
