import { Component, OnInit, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { WebPlayerUrls } from '../web-player-urls.service';
import { Tag } from '@common/core/types/models/Tag';
import { finalize } from 'rxjs/operators';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';
import { object } from 'dot-object';
import { CurrentUser } from '@common/auth/current-user';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { Router } from '@angular/router';
import { Toast } from '@common/core/ui/toast.service';
import {WebPlayerState} from '../web-player-state.service';

@Component({
    selector: 'app-choose-club',
    templateUrl: './choose-club.component.html',
    styleUrls: ['./choose-club.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseClubComponent implements OnInit {
    public tags = new BehaviorSubject(object);
    loading: boolean = true;
    idUser: any;
    selected: any;
    userClub: any;
    alltags: any;
    constructor(
        public WebPlayer: WebPlayerUrls,
        private http: AppHttpClient,
        private currentUser: CurrentUser,
        private router: Router,
        private toast: Toast,
        public state: WebPlayerState

    ) {}

    ngOnInit() {
        this.idUser = this.currentUser.get('id');
        this.userClub = this.currentUser.get('favorite_club_id');
        this.getTagsList();
    }
    Makeselected(list){
        list.map((x) => (x.selected = false));
        if (this.userClub != null || this.userClub != '') {
            var findClub = list.find(
                (x) => x.id == this.userClub
            );
            if (findClub) {
                findClub.selected = true;
            }
        }
        this.alltags = list;
        this.tags.next(list);
    }
    async getTagsList() {
        this.loading = true;
        this.state.loading = true;
        this.WebPlayer.getTagsList().subscribe((response: any) => {
            this.Makeselected(response.pagination.data);
            this.state.loading = false;
            this.loading = false;
        });
    }
    SelectClub(id) {
        this.state.loading = true;
        this.Makeselected(this.alltags);
        this.WebPlayer.addFavoriteTag(id, this.idUser).subscribe(
            (response: any) => {
                if (response.message) {
                    this.currentUser.set(
                        'favorite_club_id',
                        response[0].favorite_club_id
                    );
                    const tag = this.alltags.find(
                        (t) => t.id == response[0].favorite_club_id
                    );
                    if (tag) {
                        this.currentUser.set('favoriteClubCover', tag.cover_image);
                    }
                    this.state.loading = false;
                    this.toast.open(response.message);
                    this.router.navigate(['/']);
                } else {
                }
            },
            (errResponse: BackendErrorResponse) => {
                this.state.loading = false;
                if (errResponse.errors.idUser) {
                    this.toast.open(errResponse.errors.idUser);
                } else {
                    if (errResponse.errors.idUser) {
                        this.toast.open(errResponse.errors.club_id);
                    }else{
                        this.toast.open(errResponse.message);
                    }
                }
            }
        );
    }
}
