<!-- <material-navbar [hideRegisterButton]="state.mode === 'pricing'"></material-navbar> -->
<div class="header-container" style="margin:0 !important;display: flex;align-items: center;" (click)="goBack()">
    <button class="back-btn">
        <mat-icon svgIcon="keyboard-arrow-left"></mat-icon>
    </button>
    <h1 trans style="margin: 0;">{{state.mode}}</h1>
</div>
<div class="bg-wrapper qsp-upgrade-page">
    <mat-horizontal-stepper class="container main-content">
        <ng-template matStepperIcon="edit">
            <mat-icon svgIcon="edit" class="size-16"></mat-icon>
        </ng-template>
        <mat-step [label]="'Subscription plan' | trans">
            <app-select-plan-payment-method (selected)="nextStep()" [settings]="settings">
            </app-select-plan-payment-method>
        </mat-step>
        <mat-step [label]="'Select plan' | trans" *ngIf="method!='voucher'">
            <select-plan-panel (selected)="nextStep()" [selectedGateway]="selectedGateway"></select-plan-panel>
            <button mat-raised-button class="primary-button" color="default" (click)="stepper.previous()" trans style="
            margin-top: 10px; width: 100%;">Back</button>
        </mat-step>

        <!-- <mat-step [label]="'Subscription interval' | trans" [completed]="!!(state.selectedPlan$ | async)">
            <select-plan-period-panel [showSidebar]="true" (selected)="nextStep()"></select-plan-period-panel>
        </mat-step> -->

        <mat-step [label]="'Payment' | trans" [completed]="false">
            <div class="step-container payment-step qsp-stepper">
                <div class="left-col">
                    <create-phone-subscription-panel [showSidebar]="true" (selected)="nextStep()"
                        *ngIf="method=='eklectic'" [plan]="state.selectedPlan$ | async" [stepper]="stepper">
                    </create-phone-subscription-panel>
                    <create-subscription-panel [plan]="state.selectedPlan$ | async" (loading)="loading$.next($event)"
                        (completed)="onCompleted()" *ngIf="method=='stripe' && (state.selectedPlan$ | async)" [stepper]="stepper">
                    </create-subscription-panel>
                    <create-voucher-subscription-panel *ngIf="method=='voucher'" [stepper]="stepper"></create-voucher-subscription-panel>
                    <create-paymee-subscription-panel *ngIf="method=='paymee' && (state.selectedPlan$ | async)"
                        [plan]="state.selectedPlan$ | async" [showSidebar]="true" (selected)="nextStep()"
                        [settings]="settings" [stepper]="stepper">
                    </create-paymee-subscription-panel>
                    <create-sobflous-subscription-panel *ngIf="method=='sob_flous' && (state.selectedPlan$ | async)"
                        [plan]="state.selectedPlan$ | async" [showSidebar]="true" (selected)="nextStep()"
                        [settings]="settings" [stepper]="stepper">
                    </create-sobflous-subscription-panel>
                </div>
                <!-- <upgrade-page-aside [plan]="state.selectedPlan$ | async" class="right-col"></upgrade-page-aside> -->
            </div>


            <!-- <p class="legal-info" *ngIf="state.selectedPlan$ | async as plan" trans [transValues]="{
                    siteName: settings.get('branding.site_name'),
                    intervalCount: plan.interval_count > 1 ? plan.interval_count : '',
                    intervalName: plan.interval_count > 1 ? plan.interval + 's' : plan.interval
                }">
                You authorise :siteName to charge you automatically every :intervalCount :intervalName, until you cancel
                your subscription. You consent to get access to your :siteName subscription immediately, and acknowledge
                that refunds are only available within 14 days and provided you haven't accessed :siteName. You will be
                able to cancel your subscription at any time from your profile page.
            </p> -->
        </mat-step>
    </mat-horizontal-stepper>
</div>