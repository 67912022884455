<ng-container *ngIf="channel$ | async as channel">
    <div class="header-container" *ngIf="!channel.config.hideTitle" routerLink="/" style="cursor:pointer">
        <button class="back-btn">
            <mat-icon svgIcon="keyboard-arrow-left"></mat-icon>
        </button>
        <h1 trans>{{channel.name}}</h1>
        <div class="header-actions" *ngIf="channel.config.actions">
            <a type="button" class="header-action" [matTooltip]="action.tooltip | trans" [routerLink]="action.route"
                mat-icon-button *ngFor="let action of channel.config.actions">
                <mat-icon [svgIcon]="action.icon"></mat-icon>
            </a>
        </div>
    </div>
    <ad-host slot="ads.general_top"></ad-host>
    <channel-content [queney]="queney" [channel]="channel" [isGenrePage]="isGenrePage" [isSingersPage]="isSingersPage">
    </channel-content>
    <ad-host slot="ads.general_bottom" class="margin-bottom"></ad-host>
</ng-container>