import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '@common/core/ui/custom-menu/menu-item';
import { slugifyString } from '@common/core/utils/slugify-string';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { filter } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { WebPlayerState } from 'src/app/web-player/web-player-state.service';
@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
    @ViewChild('stepper', { static: false }) stepper: MatStepper;
    public loading$ = new BehaviorSubject<boolean>(false);
    public registerPolicies: Partial<MenuItem>[] = [];
    public form = this.fb.group({
        email: [''],
        phoneNumber: [''],
        password: [''],
        password_confirmation: [''],
        purchase_code: [''],
        code: [''],
    });
    public errors$ = new BehaviorSubject<{
        email?: string;
        phoneNumber?: string;
        password?: string;
        general?: string;
        purchase_code?: string;
        otp?: string;
    }>({});
    @Output() goToHome = new EventEmitter();
    @Output() goNext = new EventEmitter();
    userId: any;

    constructor(
        public auth: AuthService,
        public socialAuth: SocialAuthService,
        public settings: Settings,
        public route: ActivatedRoute,
        private user: CurrentUser,
        private router: Router,
        private toast: Toast,
        private bootstrapper: Bootstrapper,
        private recaptcha: RecaptchaService,
        private fb: FormBuilder,
        public state: WebPlayerState
    ) {}

    ngOnInit() {
        this.registerPolicies = this.settings.getJson('register_policies', []);
        this.registerPolicies.forEach((policy) => {
            policy.id = slugifyString(policy.label, '_');
            this.form.addControl(policy.id, new FormControl(false));
        });
        if (this.recaptcha.enabledFor('registration')) {
            this.recaptcha.load();
        }
        this.auth.forcedEmail$.pipe(filter((email) => !!email)).subscribe((email) => {
            this.form.get('email').setValue(email);
            this.form.get('email').disable();
        });
        const secret = this.route.snapshot.queryParams.jayegSecret;
        localStorage.setItem('secret', secret);

    }

    public async register() {
        this.loading$.next(true);
        if (
            this.recaptcha.enabledFor('registration') &&
            !(await this.recaptcha.verify('registration'))
        ) {
            this.loading$.next(false);
            return this.toast.open('Could not verify you are human.');
        }
        let reg = new RegExp(/\S+@\S+\.\S+/);
        var request = this.form.value;
        const matchs = reg.test(request.email);
        if (matchs) {
            delete request.phoneNumber;
        } else {
            request.phoneNumber = request.email;
            delete request.email;
        }
        this.state.loading = true;
        this.auth.register(request).subscribe(
            (response: any) => {
                if (response.message.status === 'needs_email_verification') {
                    this.userId = response.user.id;
                    this.state.loading = false;
                    this.loading$.next(false);
                    this.stepper.next();
                    this.toast.open(response.message.message, { duration: 6000 });
                } else {
                    this.bootstrapper.bootstrap(response.message.bootstrapData);
                    localStorage.setItem('auth-token', response.token);
                    this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                        this.loading$.next(false);
                        this.state.loading = false;
                        this.toast.open('Registered successfully.');
                    });
                }
            },
            (errResponse: BackendErrorResponse) => {
                this.errors$.next(errResponse.errors);
                this.state.loading = false;
                this.loading$.next(false);
            }
        );
    }

    public async VerifyAccount() {
        this.loading$.next(true);
        this.state.loading = true;
        this.auth.verifyOtp(this.userId, this.form.value.code).subscribe(
            (response: any) => {
                if (response) {
                    const data = response.message.bootstrapData
                        ? response.message.bootstrapData
                        : response.bootstrapData;
                    this.bootstrapper.bootstrap(data);
                    localStorage.setItem('auth-token', response.token);
                    this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                        this.loading$.next(false);
                        this.state.loading = false;
                        this.toast.open('Registered successfully.');
                    });
                } else {
                }
            },
            (errResponse: BackendErrorResponse) => {
                this.loading$.next(false);
                this.state.loading = false;
                if (errResponse.message === 'Wrong OTP') {
                    this.errors$.next(errResponse.errors);
                    this.toast.open('Wrong OTP.');
                } else {
                    this.errors$.next(errResponse.errors);
                }
            }
        );
    }
    goHome() {
        this.router.navigate(['/']);
    }
    public resendOtp() {
        this.loading$.next(true);
        this.state.loading = true;
        this.auth.resendOtp(this.userId).subscribe(
            (response: any) => {
                if (response) {
                    this.loading$.next(false);
                    this.state.loading = false;
                    this.toast.open(response.message, { duration: 6000 });
                }
            },
            (errResponse: BackendErrorResponse) => {
                this.loading$.next(false);
                this.state.loading = false;
                // if (errResponse.message === 'Wrong OTP') {
                //     this.errors$.next(errResponse.errors);
                //     this.toast.open('Wrong OTP.');
                // } else {
                //     this.errors$.next(errResponse.errors);
                // }
            }
        );
    }
    onOtpChange(otp) {
        this.form.value.code = otp;
    }
}
