<div class="choose-club-container">
  <p style="text-align: center;margin-bottom: 40px;" trans>
    Choisissez votre club et obtenez des recommandations personnalisées
  </p>
  <div class="club-section">
    <div class="club-card-col" *ngFor="let tag of tags | async">
      <div class="club-card" (click)="SelectClub(tag.id)" [class.selected]="tag.selected">
        <h4>{{tag.name}}</h4>
        <img [src]="tag.image" width="50px" />
      </div>
    </div>
  </div>
</div>
<loading-indicator [isVisible]="state.loading" class="overlay"></loading-indicator>
