<div class="header">
    <media-image [media]="data.item"></media-image>
    <div class="meta">
        <a class="primary-name hover-underline" [routerLink]="urls.album(data.item)">{{data.item.name}}</a>
        <artists-links-list class="secondary-name" [artists]="data.item.artists"></artists-links-list>
    </div>
</div>

<div class="context-menu-panel primary-panel" [class.hidden]="!activePanelIs('primary')">
    <div class="panel-body">
        <div class="context-menu-item" (click)="addToQueue()" trans>Add to Queue</div>
        <div class="context-menu-item" (click)="openPanel('playlist'); $event.stopPropagation()"><span trans>Add to Playlist</span> <mat-icon svgIcon="keyboard-arrow-right"></mat-icon></div>
        <div class="context-menu-item" *ngIf="inLibrary()" (click)="removeFromLibrary()" trans>Remove from Your Music</div>
        <div class="context-menu-item" *ngIf="!inLibrary()" (click)="saveToLibrary()" trans>Add to Your Music</div>
        <div class="context-menu-item" (click)="copyLinkToClipboard()" trans>Copy Album Link</div>
        <div class="context-menu-item" (click)="openShareModal()" *ngIf="currentUser.isAdmin()" trans>Share</div>
        <a class="context-menu-item"  [routerLink]="urls.editAlbum(data.item)" *ngIf="canDeleteAlbum" trans>Edit</a>
        <div class="context-menu-item" (click)="maybeDeleteAlbum()" *ngIf="canDeleteAlbum" trans>Delete</div>
    </div>
</div>

<context-menu-playlist-panel class="context-menu-panel" [class.hidden]="!activePanelIs('playlist')" [tracks]="getTracks()" (close$)="openPanel('primary')"></context-menu-playlist-panel>
