import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    Input,
} from '@angular/core';
@Component({
    selector: 'app-select-plan-payment-method',
    templateUrl: './select-plan-payment-method.component.html',
    styleUrls: ['./select-plan-payment-method.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlanPaymentMethodComponent implements OnInit {
    @Output() selected = new EventEmitter();
    @Input() settings;
    country: any;
    method: any;
    constructor() {}

    ngOnInit() {
        this.country = localStorage.getItem('country');
    }
    SubmitMethod(method) {
        this.method = method;
        this.selected.emit();
    }
}
