import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WebPlayerUrls} from '../../web-player-urls.service';
import {Player} from '../../player/player.service';
import {queueId} from '../../player/queue-id';
import {Track} from '../../../models/Track';

@Component({
    selector: 'track-item',
    templateUrl: './track-item.component.html',
    styleUrls: ['./track-item.component.scss'],
    host: {class: 'media-grid-item', '[class.active]': 'playing()'},
})
export class TrackItemComponent {
    @Input() track: Track;
    @Input() disablePlayback = false;
    @Input() isTrackChannel = false;
    @Output() playTrack: EventEmitter<any> = new EventEmitter();


    constructor(
        public urls: WebPlayerUrls,
        private player: Player,
    ) {
    }

    public playing() {
        return this.player.mediaItemPlaying(this.queueId());
    }

    public async play() {
        if (this.isTrackChannel) {
            this.playTrack.emit(true);
            return;
        }
        this.player.playMediaItem(this.queueId(), [this.track]);
    }

    public pause() {
        this.player.pause();
    }

    public queueId() {
        return queueId(this.track, 'allTracks');
    }
}
