<div>
    <h2 *ngIf="withTitle" trans>Change Language</h2>

    <ng-container *ngIf="languages | async as langs; else loading">

    <div *ngFor="let localization of langs" class="logout-item" mat-menu-item (click)="changeLanguage(localization.language)">
        <span style="text-transform: capitalize" trans>{{localization.name}}</span>
    </div>

    </ng-container>
</div>


<ng-template #loading>
  <div style="display: flex;justify-content: center;align-items: center; height: 100px; padding: 32px">
      <mat-spinner></mat-spinner>
  </div>
</ng-template>
