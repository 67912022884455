import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { ThemeService } from '@common/core/theme.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { VisitorsService } from '@common/billing/upgrade-page/visitors.service';

@Component({
    selector: 'auth-page',
    templateUrl: './auth-page.component.html',
    styleUrls: ['./auth-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageComponent implements OnInit {
    @Input() infoRowTarget: 'signin' | 'signup' = 'signup';

    constructor(
        public settings: Settings,
        private theme: ThemeService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private visitorsService: VisitorsService
    ) {}

    ngOnInit() {
        if (this.route.snapshot.queryParams.email) {
            this.auth.forcedEmail$.next(this.route.snapshot.queryParams.email);
        }
        this.getCountry();
    }
    getCountry() {
        var country = localStorage.getItem('country');
        if (!country) {
            this.visitorsService.getIpAddress().subscribe((res) => {
                this.visitorsService.getGEOLocation(res['ip']).subscribe((res) => {
                    localStorage.setItem('country', res['country_code3']);
                });
            });
        }
    }

    public logoUrl() {
        return this.settings.get(
            `branding.logo_${this.theme.isDarkMode() ? 'light' : 'dark'}`
        );
    }
}
