import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Channel } from '../../../admin/channels/channel';
import { WebPlayerState } from '../../web-player-state.service';
import { Router } from '@angular/router';
import { CurrentUser } from '@common/auth/current-user';

@Component({
    selector: 'channel-show',
    templateUrl: './channel-show.component.html',
    styleUrls: ['./channel-show.component.scss'],
})
export class ChannelShowComponent implements OnInit {
    isGenrePage: boolean = false;
    isSingersPage: boolean = false;
    userClub: any;
    @Input() set channel(channel: Channel) {
        this.channel$.next(channel);
    }
    @Input() queney = false;

    public channel$ = new BehaviorSubject<Channel>(null);

    constructor(
        private route: ActivatedRoute,
        private state: WebPlayerState,
        private router: Router,
        private currentUser: CurrentUser
    ) {}

    ngOnInit() {
        this.userClub = this.currentUser.get('favorite_club_id');
        var logged = this.currentUser.isLoggedIn();
        if (logged) {
            if (
                (this.userClub == null ||
                    this.userClub == '' ||
                    this.userClub == undefined) &&
                !this.currentUser.isAdmin()
            ) {
                this.router.navigate(['choose-club']);
            }
        }
        this.isGenrePage = this.router.url.includes('genre');
        this.isSingersPage = this.router.url.includes('hot-singers');
        this.state.scrollContainer.nativeElement.scrollTop = 0;
        this.route.data.subscribe((data) => {
            if (data.api && data.api.channel) {
                this.channel$.next(data.api.channel);
            }
            if (data?.queney) {
                this.queney = !!data.queney;
            }
        });
    }
}
