<div class="header" *ngIf="!multipleTracksSelected()">
    <media-image [media]="data.item"></media-image>
    <div class="meta">
        <a class="primary-name hover-underline" [routerLink]="urls.track(data.item)">{{data.item.name}}</a>
        <artists-links-list class="secondary-name" [artists]="data.item.artists"></artists-links-list>
    </div>
</div>

<div class="context-menu-panel primary-panel" [class.hidden]="!activePanelIs('primary')">
    <div class="panel-body">
        <ng-content></ng-content>
        <div class="context-menu-item" (click)="goToTrackRadio()"
            *ngIf="!multipleTracksSelected() && !settings.get('player.hide_radio_button') && settings.get('artist_provider') === 'spotify'"
            trans>Go to Track Radio</div>
        <div class="context-menu-item" (click)="addToQueue()" *ngIf="!inQueue() || multipleTracksSelected()" trans>
            <mat-icon svgIcon="add"></mat-icon>
            <span>Add to Queue</span>
        </div>
        <div class="context-menu-item" (click)="removeFromQueue()" *ngIf="inQueue() && !multipleTracksSelected()" trans>
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Remove from Queue</span>
        </div>
        <div class="context-menu-item" (click)="openPanel('playlist'); $event.stopPropagation()">
            <mat-icon svgIcon="playlist-add"></mat-icon>
            <span trans>Add to Playlist</span>
        </div>
        <div class="context-menu-item" (click)="saveToLibrary()" *ngIf="!inLibrary() || multipleTracksSelected()" trans>
            <mat-icon svgIcon="favorite-border"></mat-icon>
            Save to Your Music
        </div>
        <div class="context-menu-item active" (click)="removeFromLibrary()"
            *ngIf="inLibrary() && !multipleTracksSelected()" trans>
            <mat-icon svgIcon="favorite"></mat-icon>
            <span>Remove from Your Music</span>
        </div>
        <div class="context-menu-item" (click)="showLyricsModal()"
            *ngIf="!multipleTracksSelected() && !settings.get('player.hide_lyrics')">
            <mat-icon svgIcon="filter-list"></mat-icon>
            <span trans> Show Lyrics</span>
        </div>
        <div class="context-menu-item" (click)="MakeTrackRbt()" *ngIf="data.item.rbt && data.item.rbt!==null" trans>
            <mat-icon svgIcon="audiotrack"></mat-icon>
            <span> RBT</span>
        </div>
        <div class="context-menu-item" (click)="copyLinkToClipboard()" *ngIf="!multipleTracksSelected()" trans>
            <mat-icon svgIcon="link"></mat-icon>
            <span> Copy Track Link</span>
        </div>
        <div class="context-menu-item" (click)="openShareModal()"
            *ngIf="!multipleTracksSelected() && currentUser.isAdmin()" trans>
            <mat-icon svgIcon="share"></mat-icon>
            <span>Share</span>
        </div>
        <div class="context-menu-item" (click)="downloadTrack()"
            *ngIf="!multipleTracksSelected() && !shouldHideDownloadButton && data.item.url" trans>Download</div>
        <a class="context-menu-item" [routerLink]="urls.editTrack(data.item)" *ngIf="canEditTrack" trans>
            <mat-icon svgIcon="edit"></mat-icon>
            <span>Edit</span>
        </a>
        <div class="context-menu-item" (click)="maybeDeleteTrack()" *ngIf="canDeleteTrack" trans>
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Delete</span>
        </div>
    </div>
</div>

<context-menu-playlist-panel class="context-menu-panel" [class.hidden]="!activePanelIs('playlist')"
    [tracks]="getTracks()" (close$)="openPanel('primary')"></context-menu-playlist-panel>